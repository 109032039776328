<script setup lang="ts">
declare const window: any;

import {timeAgo, time} from '../helpers';

useHead({
    titleTemplate: 'The Political Room - Medio de comunicación',
    bodyAttrs: {
        class: `home-template global-hash-load-more`
    },
});

const localePath = useLocalePath();
const {locale} = useI18n();

const totalArticles = ref(0);
const articlesData = ref([] as any);
const featuredArticlesData = ref([] as any);
const isReady = ref(false);
const position = ref(0);

const loadArticlesData = async ({position = 0, featured = false}: any): Promise<any> => {
    return await $fetch(`/api/strapi/articles`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            locale: locale.value,
            position,
            featured,
        })
    });
}

const loadMoreArticles = async (): Promise<any> => {
    position.value = position.value + 16;
    const {articles}: any = await loadArticlesData({position: position.value});
    articlesData.value = articlesData.value.concat(articles);
}

onMounted(async () => {
    const {articles}: any = await loadArticlesData({position: position.value});
    totalArticles.value = articles.length;
    articlesData.value = articles;
    featuredArticlesData.value = articles.filter((article: any) => article.featured).slice(0, 10);

    if (articlesData.value) {
        isReady.value = true;
    }

    setTimeout(() => {
        /* Custom settings for Flickity */
        var slider = document.getElementById('slider'),
            sliderTrigger = document.querySelector('.section-scrollable .section-featured');

        if (sliderTrigger) {

            var flkty = new window.Flickity(slider, {
                wrapAround: true,
                contain: true,
                adaptiveHeight: true,
                accessibility: false,
                prevNextButtons: false,
                pageDots: false,
                selectedAttraction: .025,
                friction: .3,
                dragThreshold: 5
            });

            /* Next button */
            var nextButton = document.getElementById('next');
            if (nextButton) {
                nextButton.addEventListener('click', function () {
                    flkty.next();
                });
            }

            /* Add class to "loop" when is slider */
            var loop = document.getElementById('loop');
            if (loop && flkty.cells.length > 0) {
                loop.classList.remove('no-featured');
                loop.classList.add('is-featured');
            }

            /* iOS 12 & 13 fix */
            let tapArea;
            let startX = 0;
            tapArea = document.querySelectorAll('.section-scrollable');
            for (let item of tapArea) {
                item.ontouchstart = function (e) {
                    startX = e.touches[0].clientX;
                };
                item.ontouchmove = function (e) {
                    if (Math.abs(e.touches[0].clientX - startX) > 5 && e.cancelable) {
                        e.preventDefault();
                    }
                };
            }
        }

    }, 500);


});

</script>

<template>
    <div>

        <Head>
            <Title></Title>
            <Meta name="description" :content="'Medio de comunicación independiente'"/>
            <Html :lang="locale"/>
        </Head>

        <div v-if="isReady">

            <div id="slider" class="section-scrollable">

                <div class="section-featured is-featured-image" v-for="featuredArticle in featuredArticlesData"
                     :key="featuredArticle.id">
                    <div class="featured-image"
                         :style="{ backgroundImage: `url(${featuredArticle.cover && featuredArticle.cover[0].url})` }"></div>
                    <div class="featured-wrap flex">
                        <article class="featured-content">
                            <span class="featured-label global-tag test-55">
                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M22.9712403,8.05987765 L16.2291373,8.05987765 L12.796794,0.459688839 C12.5516266,-0.153229613 11.4483734,-0.153229613 11.0806223,0.459688839 L7.64827899,8.05987765 L0.906176009,8.05987765 C0.538424938,8.05987765 0.170673866,8.30504503 0.0480901758,8.6727961 C-0.0744935148,9.04054717 0.0480901758,9.40829825 0.293257557,9.65346563 L5.31918887,14.3116459 L3.11268244,22.4021694 C2.99009875,22.7699205 3.11268244,23.1376716 3.48043351,23.382839 C3.72560089,23.6280063 4.21593565,23.6280063 4.46110303,23.5054227 L11.9387082,19.2149935 L19.4163133,23.5054227 C19.538897,23.6280063 19.6614807,23.6280063 19.906648,23.6280063 C20.1518154,23.6280063 20.2743991,23.5054227 20.5195665,23.382839 C20.7647339,23.1376716 20.8873176,22.7699205 20.8873176,22.4021694 L18.6808111,14.3116459 L23.7067424,9.65346563 C23.9519098,9.40829825 24.0744935,9.04054717 23.9519098,8.6727961 C23.7067424,8.30504503 23.3389914,8.05987765 22.9712403,8.05987765 Z"></path>
                                </svg> 
                                Destacado
                            </span>
                            <h2>
                                <NuxtLink
                                    :to="localePath({ name: 'blog-slug', params: { slug: featuredArticle.slug } })">
                                    {{ featuredArticle.title }}
                                </NuxtLink>
                                <span class="featured-dot"></span>
                            </h2>
                            <div class="item-meta white">
                                <span>por </span>
                                <NuxtLink
                                    :to="localePath({ name: 'colaborate-id', params: { id: featuredArticle && featuredArticle.author && featuredArticle.author.id } })"
                                    class="author-name white">
                                    {{
                                        (featuredArticle && featuredArticle.author && featuredArticle.author.firstname) + ' ' + (featuredArticle && featuredArticle.author && featuredArticle.author.lastname ? ' ' + featuredArticle.author.lastname : '')
                                    }}
                                </NuxtLink>
                                <div v-if="featuredArticle.other_authors && featuredArticle.other_authors.length > 0"
                                     style="display: inline">
                                    /
                                    <NuxtLink
                                        :to="localePath({ name: 'colaborate-id', params: { id: featuredArticle.other_authors[0].id } })"
                                        class="author-name white">
                                        {{ featuredArticle.other_authors[0].firstname || 'otro autor' }}
                                    </NuxtLink>
                                </div>
                                <br>
                                <time>{{ time(featuredArticle.publishedAt) }}</time>
                            </div>
                        </article>
                    </div>
                </div>

            </div>

            <div id="next" class="scrollable-nav">
                <span class="next"></span>
            </div>

            <div id="loop" class="section-loop wrap is-featured">
                <div class="items-wrap flex">
                    <div class="item-wrap flex post is-image" v-for="article in articlesData" :key="article.id">
                        <article>
                            <NuxtLink :to="localePath({ name: 'blog-slug', params: { slug: article.slug } })"
                                      class="item-link-overlay" :aria-label="article.title"></NuxtLink>

                            <div class="item-image"
                                 :style="{ backgroundImage: `url(${article.cover && article.cover[0].url})` }"></div>

                            <NuxtLink :to="localePath({ name: 'blog-slug', params: { slug: article.slug } })"
                                      class="white">
                                <h2> {{ article.title }} </h2>
                            </NuxtLink>

                            <div class="item-meta white is-primary-tag is-members-label">
                                <span>por </span>
                                <NuxtLink :to="localePath({ name: 'colaborate-id', params: { id: article.author.id } })"
                                          class="author-name white">
                                    {{
                                        (article && article.author && article.author.firstname) + ' ' + (article && article.author && article.author.lastname ? ' ' + article.author.lastname : '')
                                    }}
                                </NuxtLink>
                                <div v-if="article.other_authors && article.other_authors.length > 0"
                                     style="display: inline">
                                    /
                                    <NuxtLink
                                        :to="localePath({ name: 'colaborate-id', params: { id: article.other_authors[0].id } })"
                                        class="author-name white">
                                        {{ article.other_authors[0].firstname || 'otro autor' }}
                                    </NuxtLink>
                                </div>
                                <br>
                                <time>{{ time(article.publishedAt) }}</time>
                            </div>
                            <a class="primary-tag global-tag white" href="#">{{
                                    article.article_categories && article.article_categories[0] && article.article_categories[0].name
                                }}</a>
                            <a class="secondary-tag global-tag white" href="#"
                               v-if="article && article.premium">PREMIUM</a>
                        </article>
                    </div>
                </div>
            </div>

            <div class="section-load-more">
                <div @click="loadMoreArticles()" class="load-more" style="display: inline-block">
                    <span>Cargar más</span>
                </div>
            </div>

        </div>
        <div v-else class="spinner">
            <Spinner/>
        </div>

        <SubscribeForm/>

    </div>
</template>

<style>


</style>